<template>
  <home-button></home-button>
  <video src="video/about.webm" autoplay muted></video>
</template>

<script>
import HomeButton from '@/views/components/homeButton.vue'
import { defineComponent } from 'vue'
// import { UnsubscribeFunction } from '@pb/api/dist/src/services/service'

export default defineComponent({
  name: 'caseAbout',
  components: { HomeButton },
  unsubscribers: [],
  mounted () {
    this.$robot.dialogService.sayReplicByName('ABOUT.MAIN')
    this.$robot.dialogService.onRobotReplicStart(() => {})
    // this.$robot.dialogService.onRobotReplicFinish(() => { history.back() })
    this.unsubscribers = [
      this.$robot.dialogService.onRobotReplicFinish(() => {
        history.back()
      })
    ]
  },
  unmounted () {
    this.$robot.dialogService.abortRobotReplic()
  },

  beforeUnmount () {
    this.unsubscribers.forEach(unsubscribe => unsubscribe())
  }
})
</script>
