<script lang="ts">
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'BuildingNavigation',
  components: { CaseAside },
  data: function () {
    return {
      loading: false,
      navigation: {},
      showNavigation: false,
      navigationType1: [
        {
          name: 'scenarios.navigation.types.apartment',
          ico: 'solar_bell'
        },
        {
          name: 'scenarios.navigation.types.floor',
          ico: 'solar_bell'
        },
        {
          name: 'scenarios.navigation.types.entrance',
          ico: 'solar_bell'
        }
      ],
      navigationType2: [
        {
          name: 'scenarios.navigation.types.child',
          ico: 'solar_bell',
          type: 'child'
        },
        {
          name: 'scenarios.navigation.types.sport',
          ico: 'solar_bell',
          type: 'sport'
        },
        {
          name: 'scenarios.navigation.types.garbage',
          ico: 'solar_bell',
          type: 'garbage'
        },
        {
          name: 'scenarios.navigation.types.yard',
          ico: 'solar_bell',
          type: 'yard'
        },
        {
          name: 'scenarios.navigation.types.commercial',
          ico: 'solar_bell',
          type: 'commercial'
        },
        {
          name: 'scenarios.navigation.types.office',
          ico: 'solar_bell',
          type: 'office'
        }
      ]
    }
  },
  async mounted () {
    // this.loading = true
    // this.data = await fetch('http://localhost:8000/navagation').then(res => res.json())
    //   .then(data => {
    //     this.loading = false
    //     return data
    //   })
    // console.log(this.data.data.text)
  },
  methods: {
    async openNavigation (type: string) {
      this.showNavigation = true
      this.loading = true
      this.navigation = await fetch(`${Env.get('backend_host')}/navigation/` + type).then(res => res.json())
        .then(data => {
          this.loading = false
          return data
        })
      console.log(this.navigation)
    }
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>
    <div class="main" style="width: 1560px;" v-if="!showNavigation">
      <h1>{{ $t('scenarios.navigation.title') }}</h1>
      <div class="buttons">
        <router-link v-for="navigation in navigationType1" :to="navigation.name" v-bind:key="navigation.name">
          <div class="card">
            <div class="top"><img :src="require(`@/assets/icons/${navigation.ico}.svg`)" alt=""/></div>
            <div class="bottom">{{ $t(navigation.name) }}</div>
          </div>
        </router-link>
      </div>
      <div class="buttons">
        <div class="card" v-for="navigation in navigationType2" v-bind:key="navigation.type"
             @click="openNavigation(navigation.type)">
          <div class="top"><img :src="require(`@/assets/icons/${navigation.ico}.svg`)" alt=""/></div>
          <div class="bottom">{{ $t(navigation.name) }}</div>
        </div>
      </div>
    </div>
    <div class="main" style="width: 1560px;" v-if="showNavigation">
      <h1>{{ $t('scenarios.navigation.title') }}</h1>
      <div class="navigation-list">
        <div v-for="(item, index) in navigation.result" v-bind:key="item" class="navigation">
          <!--          <div class="date">{{ formatDate(item.created_at) }}</div>-->
          <div class="index">{{ index+1 }}</div>
          <div class="number">{{ item }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.buttons {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  gap: 20px;
}

.buttons div.card {
  background: #fff;
  width: 334px;
  height: 195px;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  box-shadow: 0 40px 40px 0 #000E221A;
  gap: 12px;
  border-radius: 30px;
}

div.top {
  height: 100px;
}

div.top img {
  width: 50px;
  height: 50px;
  margin-top: 37px;
}

div.bottom {
  height: 95px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

div.title span {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 30px;
  margin-right: -30px;
  margin-left: 0;
}

.navigation-list {
  height: 830px;
  overflow: auto;
}

.navigation {
  width: 1500px;
  height: 109px;
  padding: 0 0 0 20px;
  gap: 30px;
  display: flex;
  border-radius: 24px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  align-items: center;
  box-shadow: 0 20px 60px 0 rgba(130, 0, 4, 0.12);
  font-size: 40px;
  font-weight: 400;
  line-height: 54px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.navigation .index {
  background-color: var(--main-color);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  align-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 54px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

</style>
