<template>
  <home-button></home-button>
  <div style="background: #000">
    <div class="container">
      <video v-if="currentFileType==='video'" :src="currentFile" autoplay muted @error="getNextFile"
             @ended="getNextFile">
      </video>
      <img v-else :src="currentFile" alt=""/>
    </div>
  </div>
</template>

<script>
// import { UnsubscribeFunction } from '@pb/api/dist/src/services/service'
import { defineComponent } from 'vue'
import HomeButton from '@/views/components/homeButton.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'CasePromo',
  components: { HomeButton },
  data () {
    return {
      files: Env.get('PROMO.files'),
      phrases: Env.get('PROMO.phrases'),
      currentFile: '',
      currentFileType: false,
      filePath: 'media',
      currentIndex: 0,
      currentPhraseIndex: 0,
      playFirstTime: true,
      intervalHandler: 0,
      phraseInterval: Env.get('PROMO.phrase_timeout') * 1000,
      photoInterval: Env.get('PROMO.slideshow_timeout') * 1000
    }
  },

  mounted () {
    this.getNextFile()
    this.playNextPhrase()
    this.intervalHandler = setInterval(() => {
      this.playNextPhrase()
    }, this.phraseInterval)
  },

  unmounted () {
    this.currentPhraseIndex = 0
    this.currentIndex = 0
    clearInterval(this.intervalHandler)
  },

  beforeUnmount () {
    // this.unsubscribers.forEach(unsubscribe => unsubscribe())
  },

  methods: {
    playNextPhrase () {
      if (this.currentPhraseIndex >= this.phrases.length) {
        this.currentPhraseIndex = 0
      }
      this.$robot.dialogService.sayText(this.phrases[this.currentPhraseIndex])
      this.currentPhraseIndex++
    },
    getNextFile () {
      if (this.currentIndex >= this.files.length) {
        this.currentIndex = 0
      }
      const ext = this.files[this.currentIndex].split('.').reverse()[0]
      if (ext === 'webm' || ext === 'mp4') {
        this.currentFileType = 'video'
      } else {
        this.currentFileType = 'image'
        setTimeout(() => {
          this.getNextFile()
        }, this.photoInterval)
      }
      this.currentFile = this.filePath + '/' + this.files[this.currentIndex]
      this.currentIndex = this.currentIndex + 1
      if (this.playFirstTime) {
        this.playFirstTime = false
      } else {
        this.play()
      }
    },
    play () {
      if (document.getElementsByClassName('container')[0]) {
        document.getElementsByClassName('container')[0].classList.remove('fadeIn')
        window.requestAnimationFrame(function () {
          window.requestAnimationFrame(function () {
            document.getElementsByClassName('container')[0].classList.add('fadeIn')
          })
        })
      }
    }
  }
}
)
</script>

<style scoped>
video {
  background-color: black;
  margin: 0 auto;
  display: block;
  height: 1080px;
  width: 1920px;
  object-fit: contain;
}

img {
  background-color: black;
  height: 1080px;
  width: 1920px;
  object-fit: contain
}

.fadeIn {
  animation: fadeIn 0.5s;
  background-color: black;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
