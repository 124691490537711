<template>
  <div class="title">
    <div class="back-button">
      <a @click="$router.back()">
        <div style="align-content: center; text-align: center;">
          <img src="@/assets/icons/arrow-left.svg" alt="Back"/>
        </div>
      </a>
    </div>
    <span></span>
    <div class="menu">
      <div><language-selector /></div>
      <div @click="minimize"><img src="@/assets/icons/collapse.svg" alt=""/></div>
      <router-link to="/">
        <div><img src="@/assets/icons/cross.svg" alt=""/></div>
      </router-link>
    </div>
  </div>
  <router-view/>
</template>

<script>
import router from '@/router'
import LanguageSelector from '@/views/components/languageSelector.vue'

export default {
  name: 'CaseSettings',
  components: { LanguageSelector },
  methods: { router, minimize () { this.$robot.settingsService.minimize() } }
}
</script>

<style scoped>

</style>
