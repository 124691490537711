<script>
import CaseAside from '@/views/components/CaseAside.vue'
// import HomeButton from '@/views/components/homeButton.vue'
// import { Env } from '@pb/configuration-helper'

export default {
  name: 'CaseStart',
  components: {
    CaseAside
  },
  data () {
    return {}
  },
  mounted () {

  },
  methods: {},
  computed: {}
}
</script>
<template>
  <div>
    <div style="width: 360px;">
      <case-aside/>
    </div>
    <div class="title">
      Арди<br/>Консьерж
    </div>
    <router-link to="/menu">
      <button class="start btn-main start-button btn-big">СТАРТ</button>
    </router-link>
  </div>
</template>

<style scoped>
div.title {
  font-size: 120px;
  font-weight: 500;
  line-height: 120px;
  text-align: left;
  position: absolute;
  left: 1132px;
  top: 106px;
}

.start-button {
  position: absolute;
  left: 1140px;
  bottom: 120px;
}
</style>
