import Promobot from '@pb/api'
import MessageChannelQWebChannel from '@pb/api/dist/src/message_channel_qwebchannel'
import MessageChannelEmulator from '@pb/api/dist/src/message_channel_emulator'

export class PromobotAugmented extends Promobot {
  public static getInstance (options: unknown): Promise<PromobotAugmented> {
    return new Promise((resolve) => {
      const connection = new MessageChannelQWebChannel(options)
      connection.connect().then(() => resolve(new PromobotAugmented(connection)))
    })
  }

  public static getEmulateInstance (options: unknown): Promise<PromobotAugmented> {
    return new Promise((resolve) => {
      const connection = new MessageChannelEmulator(options)
      connection.connect().then(() => resolve(new PromobotAugmented(connection)))
    })
  }
}
