import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1dc3eaea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settingContainer" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "settingsOption" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "body" }
const _hoisted_8 = { class: "settingsOption textarea" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('settings.llm.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.LLM.id) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.getLLMSetting && _ctx.getLLMSetting(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.llmList, (item, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: index,
                value: index
              }, _toDisplayString(item), 9, _hoisted_6))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.config.LLM.id]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('settings.llm.prompt')), 1),
        _createElementVNode("div", _hoisted_10, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.prompt) = $event))
          }, null, 512), [
            [_vModelText, _ctx.prompt]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveLLMSettings && _ctx.saveLLMSettings(...args)))
      }, _toDisplayString(_ctx.$t('settings.llm.save')), 1)
    ])
  ]))
}