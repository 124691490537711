import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import createRouterWithRobot from './router'
import PromobotVueExtended from '@/extended/vue/promobot'
import { PromobotAugmented as Promobot } from '@/extended/promobot'
import { createI18n } from 'vue-i18n'
import ru from '@/locales/ru.json'
import en from '@/locales/en.json'
import hi from '@/locales/hi.json'
import ar from '@/locales/ar.json'
import { Env } from '@pb/configuration-helper'

declare global {
  export const QWebChannel: unknown
}

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  globalInjection: true,
  messages: {
    ru,
    en,
    hi,
    ar
  }
})

document.addEventListener('touchstart', (event) => {
  if (event.targetTouches.length > 1) {
    event.preventDefault()
  }
}, { passive: false })

const emulatorConfig = {
  url: 'http://consierge.rdirobots.com:8089',
  reconnection: false,
  secure: false
}

const env = (typeof QWebChannel !== 'undefined') ? 'production' : 'development'
const instance = (env === 'production')
  ? Promobot.getInstance({})
  : Promobot.getEmulateInstance(emulatorConfig)

document.addEventListener('DOMContentLoaded', async () => {
  const api = await instance

  await Env.loadConfigFile('config.json')

  api.dialogService.abortRobotReplic()
  api.settingsService.setLanguage('ru_RU')

  const router = createRouterWithRobot()

  createApp(App)
    .use(PromobotVueExtended, api)
    .use(router)
    .use(i18n)
    .mount('#app')

  globalAny.robot = api
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalAny: any = global
