import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2cfc273c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","height":"1080px"} }
const _hoisted_2 = { style: {"width":"360px"} }
const _hoisted_3 = {
  key: 0,
  class: "main",
  style: {"width":"1560px"}
}
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "top" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "bottom" }
const _hoisted_9 = { class: "buttons" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "top" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "bottom" }
const _hoisted_14 = {
  key: 1,
  class: "main",
  style: {"width":"1560px"}
}
const _hoisted_15 = { class: "navigation-list" }
const _hoisted_16 = { class: "index" }
const _hoisted_17 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaseAside = _resolveComponent("CaseAside")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CaseAside)
    ]),
    (!_ctx.showNavigation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('scenarios.navigation.title')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationType1, (navigation) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                to: navigation.name,
                key: navigation.name
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("img", {
                        src: require(`@/assets/icons/${navigation.ico}.svg`),
                        alt: ""
                      }, null, 8, _hoisted_7)
                    ]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(navigation.name)), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationType2, (navigation) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card",
                key: navigation.type,
                onClick: ($event: any) => (_ctx.openNavigation(navigation.type))
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("img", {
                    src: require(`@/assets/icons/${navigation.ico}.svg`),
                    alt: ""
                  }, null, 8, _hoisted_12)
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t(navigation.name)), 1)
              ], 8, _hoisted_10))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showNavigation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('scenarios.navigation.title')), 1),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation.result, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: "navigation"
              }, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(index+1), 1),
                _createElementVNode("div", _hoisted_17, _toDisplayString(item), 1)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}