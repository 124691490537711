<script lang="ts">
import { defineComponent } from 'vue'
import { AvailableSaveMimeType } from '@pb/api/dist/src/services/filesystem'

export default defineComponent({
  name: 'SettingsPromoPhrases',
  data () {
    return {
      config: {
        PROMO: {
          files: Array<string>(),
          phrases: Array<string>()
        }
      }
    }
  },
  mounted () {
    this.loadConfigFile()
  },
  methods: {
    async loadConfigFile () {
      this.config = await fetch('config.json').then(res => res.json())
        .then(data => {
          return data
        })
    },
    saveConfigFile () {
      this.$robot.filesystemService.saveFile(
        AvailableSaveMimeType.TEXT,
        JSON.stringify(this.config, null, '\t'),
        // '/var/www/roboshow/configs/roboshow.json'
        '/var/www/roboshow/config.json'
      )
    },
    removePhrase (idx: number) {
      this.config.PROMO.phrases.splice(idx, 1)
    },
    addPhrase () {
      this.config.PROMO.phrases.push('')
    }
  }
})
</script>

<template>
  <div class="settingContainer">
    <div class="body">
      <div v-for="(phrase, idx) in config.PROMO.phrases" :key="idx">
        <div class="settingsOption">
          <div class="name">
            <textarea v-model="config.PROMO.phrases[idx]"></textarea>
          </div>
          <div class="value checkbox">
            <img src="@/assets/icons/cross.svg" alt="" @click="removePhrase(idx)"/>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="footer">
      <button class="transparent" @click="addPhrase">+ {{ $t('settings.promo.phrases.add' )}}</button>
      <button class="" @click="saveConfigFile">{{ $t('settings.promo.phrases.save' )}}</button>
    </div>
  </div>
</template>

<style scoped>
.settingsOption {
  height: 180px;
}
</style>
