<script lang="ts">
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'

export default defineComponent({
  name: 'CaseTraffic',
  components: { CaseAside }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A4dca146e053592137b3473b6ff662eb9bda232cf4dc2920be07847cca39a543d&amp;source=constructor"
      width="100%" height="1080" frameborder="0"></iframe>
  </div>
</template>

<style scoped>

</style>
