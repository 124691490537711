import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from 'vue-router'

import CaseMain from '@/views/CaseMain.vue'
import CaseSettings from '@/views/CaseSettings.vue'
import About from '@/views/About.vue'
import Talk from '@/views/Talk.vue'
import Promo from '@/views/Promo.vue'
import SettingsSelect from '@/views/settings/SettingsSelect.vue'
import SettingsMain from '@/views/settings/SettingsMain.vue'
import SettingsLLM from '@/views/settings/SettingsLLM.vue'
import SettingsTTS from '@/views/settings/SettingsTTS.vue'
import SettingsWiFi from '@/views/settings/SettingsWiFi.vue'
import SettingsLanguage from '@/views/settings/SettingsLanguage.vue'
import SettingsPromoSelect from '@/views/settings/SettingsPromoSelect.vue'
import SettingsPromoSlideshow from '@/views/settings/SettingsPromoSlideshow.vue'
import SettingsPromoPhrases from '@/views/settings/SettingsPromoPhrases.vue'
import News from '@/views/scenarios/News.vue'
import TicketsList from '@/views/scenarios/Tickets.vue'
import Polls from '@/views/scenarios/Polls.vue'
import CaseStart from '@/views/CaseStart.vue'
import Acquaintance from '@/views/scenarios/Acquaintance.vue'
import CaseTraffic from '@/views/scenarios/Traffic.vue'
import YKInfo from '@/views/scenarios/Info.vue'
import Navigation from '@/views/scenarios/Navigation.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: CaseStart
  },
  {
    path: '/menu',
    component: CaseMain
  },
  {
    path: '/settings',
    component: CaseSettings,
    children: [
      {
        path: '',
        component: SettingsSelect
      },
      {
        path: 'main',
        component: SettingsMain
      },
      {
        path: 'llm',
        component: SettingsLLM
      },
      {
        path: 'tts',
        component: SettingsTTS
      },
      {
        path: 'wifi',
        component: SettingsWiFi
      },
      {
        path: 'languages',
        component: SettingsLanguage
      },
      {
        path: 'promo',
        component: SettingsPromoSelect
      },
      {
        path: 'promo/slideshow',
        component: SettingsPromoSlideshow
      },
      {
        path: 'promo/phrases',
        component: SettingsPromoPhrases
      }
    ]
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/talk',
    component: Talk
  },
  {
    path: '/promo',
    component: Promo
  },
  {
    path: '/news',
    component: News,
    props: { newsType: 'news' }
  },
  {
    path: '/announcements',
    component: News,
    props: { newsType: 'announcements' }
  },
  {
    path: '/tickets',
    component: Talk,
    props: { startUserPhrase: 'Создать заявку' }
  },
  {
    path: '/passes',
    component: Talk,
    props: { startUserPhrase: 'Создать пропуск' }
  },
  {
    path: '/ticketsList',
    component: TicketsList
  },
  {
    path: '/polls',
    component: Polls
  },
  {
    path: '/Acquaintance',
    component: Acquaintance
  },
  {
    path: '/traffic',
    component: CaseTraffic
  },
  {
    path: '/info',
    component: YKInfo
  },
  {
    path: '/navigation',
    component: Navigation
  }
]

function createRouterWithRobot (/* robot: Promobot */): Router {
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })

  // router.beforeEach((to, from, next) => {
  //   if (typeof to.name === 'string') {
  //     robot.dialogService.sayReplicByName(`SCREEN-${to.name.toUpperCase()}`, true)
  //   }
  //   next()
  // })

  // robot.interactionService.onStart(router.push.bind(null, '/home'))
  // robot.interactionService.onStop(router.push.bind(null, '/'))

  return router
}

export default createRouterWithRobot
