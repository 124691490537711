<script>
import HomeButton from '@/views/components/homeButton.vue'
import { Env } from '@pb/configuration-helper'
import CaseAside from '@/views/components/CaseAside.vue'

export default {
  name: 'CaseMain',
  components: {
    CaseAside,
    HomeButton
  },
  data () {
    return {
      showModal: false,
      password: '',
      scenarios: []
    }
  },
  mounted () {
    this.scenarios = Env.get('SCENARIOS')
    console.log(this.scenarios)
  },
  methods: {
    checkPassword () {
      if (this.password === Env.get('SERVICE.password')) {
        this.$router.push('/settings')
      }
    }
  },
  computed: {
    filteredScenarios () {
      return this.scenarios.filter(scenario => {
        return scenario.isEnabled === true
      })
    }
  }
}
</script>
<template>
  <div style="display: flex;">
    <div style="width: 360px;">
      <CaseAside/>
    </div>
    <div class="main" style="display: flex; width: 1560px;">
      <div class="buttons">
        <router-link v-for="scenario in filteredScenarios" :to="scenario.action" v-bind:key="scenario.action">
          <div class="card" :class="{blur: scenario.isBlur}">
            <div class="top"><img :src="require(`@/assets/icons/${scenario.imageName}.svg`)" alt=""/></div>
            <div class="bottom">{{ $t(scenario.title) }}</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal">
    <home-button action="/" @click="showModal=false"/>
    <h1>{{ $t('settings.enterpassword') }}</h1>
    <input type="password" v-model="password"/><br/>
    <button @click="checkPassword">{{ $t('settings.enter') }}</button>
  </div>
</template>

<style scoped>
.buttons {
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  padding-top: 120px;
  gap: 20px;
}

.buttons div.card {
  background: #fff;
  width: 334px;
  height: 195px;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  box-shadow: 0 40px 40px 0 #000E221A;
  gap: 12px;
  border-radius: 30px;
  //border: 4px solid #F2F2F2
}

div.top {
  height: 100px;
}

div.top img {
  width: 50px;
  height: 50px;
  margin-top: 37px;
}

div.bottom {
  height: 95px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

div.title span {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 30px;
  margin-right: -30px;
  margin-left: 0;
}

.blur {
  filter: blur(4px);
}
</style>
