<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { AvailableSaveMimeType } from '@pb/api/dist/src/services/filesystem'
import HomeButton from '@/views/components/homeButton.vue'

export default defineComponent({
  name: 'SettingsPromoSlideshow',
  components: { HomeButton },
  data () {
    return {
      mediaList: [],
      selectedFile: '',
      config: {
        PROMO: {
          files: Array<string>(),
          slideshow_timeout: 10
        }
      },
      showModal: false,
      mediaDrivesList: [],
      currentMediaDrive: null,
      filesOnExternalDrive: [],
      mediaDrivePath: '',
      selectedFilesFromExternal: []
    }
  },
  mounted () {
    this.getMediaFileList()
    this.loadConfigFile()
  },
  watch: {
    // showModal (val) {
    //   if (val) {
    //     console.log(val)
    //   }
    // },
    currentMediaDrive () {
      this.mediaDrivePath = ''
      this.loadFilesFromDrive()
    }
  },
  methods: {

    async getMediaFileList () {
      await axios({
        method: 'get',
        url: 'http://127.0.0.1:8001/media_files_list'
      }).then(response => {
        this.mediaList = response.data
      })
    },
    selectFile (media: string) {
      // document.getElementsByClassName('.settingsOption').classList.remove('selected')
      this.selectedFile = media
      const elements = document.getElementsByClassName('settingsOption')
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('selected')
      }
      document.getElementById(`file_${media}`)?.classList.add('selected')
    },
    addFileToSelected () {
      this.config.PROMO.files.push(this.selectedFile)
      this.saveConfigFile()
    },
    removeFromSelected (media: string) {
      this.config.PROMO.files.splice(this.config.PROMO.files.indexOf(media), 1)
      this.saveConfigFile()
    },
    async loadConfigFile () {
      this.config = await fetch('config.json').then(res => res.json())
        .then(data => {
          return data
        })
    },
    saveConfigFile () {
      this.$robot.filesystemService.saveFile(
        AvailableSaveMimeType.TEXT,
        JSON.stringify(this.config, null, '\t'),
        // '/var/www/roboshow/configs/roboshow.json'
        '/var/www/roboshow/config.json'
      )
    },
    async addFilesFromMedia () {
      this.showModal = true
      this.mediaDrivesList = await fetch('http://127.0.0.1:8001/media_list').then(res => res.json())
        .then(data => {
          return data
        })
    },
    async loadFilesFromDrive () {
      this.filesOnExternalDrive = await fetch(`http://127.0.0.1:8001/read_folder?media=${this.currentMediaDrive}&path=${this.mediaDrivePath}`).then(res => res.json())
        .then(data => {
          return data
        })
    },
    clickOnExternalMediaRow (fileName: string, isFolder: boolean) {
      if (isFolder) {
        this.mediaDrivePath += `${fileName}/`
        this.loadFilesFromDrive()
      }
    },
    async moveFilesFromExternal () {
      await fetch(`http://127.0.0.1:8001/copy_files?media=${this.currentMediaDrive}&path=${this.selectedFilesFromExternal}`).then(res => res.json())
        .then(data => {
          return data
        })
      await this.getMediaFileList()
    }
  }
})
</script>

<template>
  <div class="settingContainer mediaFilesList">
    <div class="body">
      <div v-for="media in mediaList" :key="media">
        <div class="settingsOption" :id="`file_${media}`" @click="selectFile(media)">
          <div class="name">
            {{ media }}
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="footer">
      <button class="transparent" @click="addFilesFromMedia">+ {{ $t('settings.promo.slideshow.loadFile') }}</button>
    </div>
  </div>

  <div class="back-button" @click="addFileToSelected"
       style="position: absolute; left: 670px; top: 500px; background: #22242B1A; border-radius: 50%; width: 80px; height: 80px; padding: 0;">
  </div>

  <div class="settingContainer selectedFilesList">
    <div class="body">
      <div v-for="media in config.PROMO.files" :key="media">
        <div class="settingsOption">
          <div class="name">
            {{ media }}
          </div>
          <div class="value checkbox">
            <img src="@/assets/icons/cross.svg" alt="" @click="removeFromSelected(media)"/>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="footer">
      <button class="transparent" @click="saveConfigFile">Save</button>
    </div>
  </div>

  <div class="settingContainer timeout">
    <div class="body">
      <div class="settingsOption">
        <div class="name">{{ $t('settings.promo.slideshow.timeout') }}</div>
        <div class="value">
          <input type="number" v-model="config.PROMO.slideshow_timeout"/>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal" class="modal">
    <home-button action="/settings/promo/slideshow" @click="showModal=false"/>
    <div class="settingContainer">
      <div class="top">
        <select v-model="currentMediaDrive">
          <option v-for="(item, idx) in mediaDrivesList" :key="idx" :value="idx">{{ idx }}</option>
        </select>
      </div>
      <div class="body">
        <div v-for="(is_folder, file_name) in filesOnExternalDrive" :key="file_name">
          <div class="settingsOption" @click="clickOnExternalMediaRow(file_name, is_folder)">

            <div class="name">
              {{ file_name }}
            </div>
            <div class="value checkbox" v-if="!is_folder">
              <input type="checkbox" :id="`cb${file_name}`" :value="mediaDrivePath + file_name"
                     v-model="selectedFilesFromExternal"/>
              <label :for="`cb${file_name}`"/>
            </div>

          </div>
          <div class="divider"></div>
        </div>
      </div>
      <div class="footer">
        <button @click="moveFilesFromExternal()">{{ $t('settings.promo.slideshow.copy') }}</button>
      </div>
    </div>

  </div>
</template>

<style scoped>
.mediaFilesList {
  width: 500px;
  margin-left: 130px;
}

.selected {
  background: #22242B1A;
}

.selectedFilesList {
  width: 1000px;
  position: absolute;
  top: 112px;
  right: 130px;
  height: 668px;
}

.timeout {
  position: absolute;
  width: 1000px;
  top: 846px;
  right: 130px;
  height: 95px;
  min-height: 95px;
}
</style>
