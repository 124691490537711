<script>
export default {
  name: 'homeButton',
  props: {
    action: {
      type: String,
      default: '/'
    }
  }
}
</script>

<template>
  <router-link :to="action">
    <div class="home-button">
      <img src="@/assets/icons/cross.svg" alt=""/>
    </div>
  </router-link>
</template>

<style scoped>
div.home-button {
  position: absolute;
  width: 80px;
  background-color: white;
  height: 80px;
  border-radius: 50%;
  margin: 16px;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  border: 2px solid #22242B1A;
}

div.home-button img {
  width: 48px;
  height: 48px;
}
</style>
