<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingsPromoSelect',
  data () {
    return {
      cards: [{
        title: 'settings.promo.slideshow.title',
        image: 'slideshow.svg',
        to: '/settings/promo/slideshow'
      }, {
        title: 'settings.promo.phrases.title',
        image: 'phrases.svg',
        to: '/settings/promo/phrases'
      }]
    }
  }
})
</script>

<template>
  <div class="cards">
    <router-link :to="card.to" v-for="card in cards" :key="card.id">
      <div class="card">
        <div class="top">
          <img v-if="card.image" :src="require(`@/assets/icons/${card.image}`)" alt=""/>
        </div>
        <div class="bottom">{{ $t(card.title) }}</div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
.cards {
  padding-top: 60px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 1100px;
  margin: 0 auto;
}

.card {
  width: 490px;
  height: 252px;
  border: 4px solid #F2F2F2;
  box-shadow: 0 2px 4px 0 #0E111D40;
  padding: 48px 0 0 0;
  gap: 24px;
  border-radius: 12px 0 0 0;
  background-color: #ffffff;
}

.card .top {
  height: 80px;
  padding: 48px 0 0 0;
  width: 100%;
  text-align: center;
}

.card .bottom {
  padding: 24px 0 0 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;

}
</style>
