<script lang="ts">
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'YKInfo',
  components: { CaseAside },
  data: function () {
    return {
      loading: false,
      data: {
        data: {
          text: '',
          title: ''
        }
      }
    }
  },
  async mounted () {
    this.loading = true
    this.data = await fetch(`${Env.get('backend_host')}/company`).then(res => res.json())
      .then(data => {
        this.loading = false
        return data
      })
    console.log(this.data.data.text)
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>
    <div>
      <h1>{{ $t('scenarios.info.title') }}</h1>
      <h2>{{ data.data.title }}</h2>
      <div  style="height: 720px; overflow: auto" v-html="data.data.text"></div>
    </div>

  </div>
</template>

<style scoped>

</style>
