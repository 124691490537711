<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'SettingsMain',
  data () {
    return {
      volume: 0,
      volumeMic: 0,
      WiFiName: '',
      LLMName: '',
      TTSName: ''
    }
  },
  computed: {
    selectedLanguages (): object {
      return Env.get('LANGUAGES') as object
    }
  },
  async mounted () {
    this.getVolume()
    this.getVolumeMic()

    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/tts'
    }).then(response => {
      this.TTSName = response.data
    })

    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/wifi_current'
    }).then(response => {
      this.WiFiName = response.data
    })

    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/llm_list'
    }).then(response => {
      this.LLMName = response.data[Env.get('LLM.id') as number]
    })
  },
  methods: {
    getVolume () {
      console.log('getvolume')
      this.$robot.settingsService.getVolume().then((val) => {
        console.log(`get volume ${val}`)
        console.log(val)
        this.volume = val
      })
    },
    setVolume () {
      console.log('setvolume ' + this.volume)
      this.$robot.settingsService.setVolume(this.volume)
    },
    getVolumeMic () {
      console.log('getvolumeMic')
      this.$robot.settingsService.getVolumeMic().then((val) => {
        console.log(`get volumeMic ${val}`)
        console.log(val)
        this.volumeMic = val
      })
    },
    setVolumeMic () {
      console.log('setvolumeMic ' + this.volumeMic)
      this.$robot.settingsService.setVolumeMic(this.volumeMic)
    }
  }
})
</script>

<template>
  <div class="settingContainer">
    <div class="body">
      <div class="settingsOption">
        <div class="name">{{ $t('settings.volume') }}</div>
        <div class="value" style="display: flex; align-items: center;">
          <input type="range" style="width: 338px" v-model="volume" @change="setVolume" />
          <div style="width: 50px; text-align: right">{{ volume }}</div>
        </div>
      </div>

      <div class="divider"></div>

      <div class="settingsOption">
        <div class="name">{{ $t('settings.micVolume') }}</div>
        <div class="value" style="display: flex; align-items: center;">
          <input type="range" style="width: 338px" v-model="volumeMic" @change="setVolumeMic" />
          <div style="width: 50px; text-align: right">{{ volumeMic }}</div>
        </div>
      </div>

      <div class="divider"></div>

      <router-link to="/settings/wifi">
        <div class="settingsOption">
          <div class="name">{{ $t('settings.wifi.title') }}</div>
          <div class="value" style="display: flex; align-items: center;">
            <div>{{ WiFiName ? WiFiName : $t('settings.wifi.no') }}</div>
            <div style="width: 50px; text-align: right"> ></div>
          </div>
        </div>
      </router-link>

      <div class="divider"></div>

      <router-link to="/settings/llm">
        <div class="settingsOption">
          <div class="name">{{ $t('settings.llm.title') }}</div>
          <div class="value" style="display: flex; align-items: center;">
            <div>{{ LLMName ? LLMName : $t('settings.llm.no') }}</div>
            <div style="width: 50px; text-align: right"> ></div>
          </div>
        </div>
      </router-link>

      <div class="divider"></div>

      <router-link to="/settings/tts">
        <div class="settingsOption">
          <div class="name">{{ $t('settings.tts.title') }}</div>
          <div class="value" style="display: flex; align-items: center;">
            <div>{{ TTSName ? TTSName : $t('settings.tts.no') }}</div>
            <div style="width: 50px; text-align: right"> ></div>
          </div>
        </div>
      </router-link>

      <div class="divider"></div>

      <router-link to="/settings/languages">
        <div class="settingsOption">
          <div class="name">{{ $t('settings.language.title') }}</div>
          <div class="value" style="display: flex; align-items: center;">
            <div>{{
                selectedLanguages.length > 0 ? selectedLanguages.filter(item => item.is_enabled).map(language => language.text).join(', ') : $t('settings.language.no')
              }}
            </div>
            <div style="width: 50px; text-align: right"> ></div>
          </div>
        </div>
      </router-link>
    </div>
  </div>

</template>

<style scoped>

</style>
