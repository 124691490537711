import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0da5da31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "top" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: card.to,
        key: card.id
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (card.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: require(`@/assets/icons/${card.image}`),
                    alt: ""
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(card.title)), 1)
          ])
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}