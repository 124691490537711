<script>
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'pollsYK',
  components: { CaseAside },
  data: function () {
    return {
      polls: {
        data: {
          polls: [{}]
        }
      },
      showItem: false,
      item: {}
    }
  },
  async mounted () {
    this.polls = await fetch(`${Env.get('backend_host')}/polls`).then(res => res.json())
      .then(data => {
        return data
      })
    console.log(this.polls.data.polls)
  },
  methods: {
    showNews: function (item) {
      this.showItem = true
      this.item = item
    }
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>
    <div class="container" v-if="polls.data.polls.length > 0 && !showItem">
      <div v-for="item in polls.data.polls" v-bind:key="item.id" @click="showNews(item)">
        <h1>{{ item.title }}</h1>
        <h4>{{ item.created_at }}</h4>
        <!--      {{ item }}-->
      </div>
    </div>
    <div class="container" v-if="showItem">
      <div>
        <h1>{{ item.title }}</h1>
        <h4>{{ item.created_at }}</h4>
        <div v-for="question in item.questions" v-bind:key="question.id">
          <h1>{{ question.title }}</h1>
          <div v-for="answer in question.options" v-bind:key="answer.id">
            <input type="radio" :id="answer.id" :name="question.id" :value="answer.id"/>
            <label :for="answer.id">{{ answer.title }}</label>
          </div>
        </div>
        <button class="btn-main">SUBMIT</button>
        <!--      {{ item }}-->
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
