<script lang="ts">
import { defineComponent } from 'vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'languageSelector',
  data () {
    return {
      isShowSelector: false
    }
  },
  computed: {
    selectedLanguages (): object {
      return Env.get('LANGUAGES') as object
    }
  },
  methods: {
    toggleSelector () {
      this.isShowSelector = !this.isShowSelector
    },
    getCurrentLanguage () {
      return this.$i18n.locale
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setLanguage (language: any) {
      this.$i18n.locale = language.imgName
      this.$robot.settingsService.setLanguage(language.action.replace('-', '_'))
      const el = document.querySelector('html')
      const dir = language.direction
      if (el !== null) {
        el.setAttribute('dir', dir ?? 'ltr')
      }
      this.toggleSelector()
    }
  }
})
</script>

<template>
  <img :src="require(`@/assets/icons/flag_${$t('share.langIcon')}.svg`)" alt="" @click="toggleSelector">
  <div class="back-layer" v-if="isShowSelector" @click="toggleSelector"></div>
  <div class="language-selector" v-if="isShowSelector">
    <div v-for="(item, index) in selectedLanguages.filter(language => language.is_enabled)" :key="index"
         class="language-row"
         :class="getCurrentLanguage() == item['imgName'] ? 'selected' : ''"
         @click="setLanguage(item)"
    >
      <div style="width: 48px; height: 48px;">
        <img src="@/assets/icons/check.svg" alt="" class="check"/>
      </div>
      <img :src="require(`@/assets/icons/flag_${item['imgName']}.svg`)" alt="" style="width: 48px; height: 48px;"/>
      <span>
        {{ item['text'] }}
      </span>
    </div>
  </div>

</template>

<style scoped>
.back-layer {
  background-color: #8B93A699;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.language-selector {
  background-color: white;
  position: absolute;
  right: 20px;
  top: 100px;
  width: 428px;
  height: auto;
  box-shadow: 0 10px 15px -8px #00000033;
  z-index: 3;
}

.language-row {
  display: flex;
  align-items: center;
  width: auto;
  margin: 0;
}

img.check {
  border: none;
  width: 48px;
  height: 48px;
  display: none;
}

.selected img.check {
  display: block;
}

.language-row span {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-left: 16px;
}
</style>
