<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { AvailableSaveMimeType } from '@pb/api/dist/src/services/filesystem'

export default defineComponent({
  name: 'SettingsLLM',
  data () {
    return {
      llmList: [],
      prompt: '',
      config: {
        PROMO: {
          files: Array<string>(),
          phrases: Array<string>()
        },
        LANGUAGES: {},
        LLM: {
          id: 0
        }
      }
    }
  },
  async mounted () {
    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/llm_list'
    }).then(response => {
      this.llmList = response.data
    })
    await this.loadConfigFile()
    await this.getPrompt()
  },
  methods: {
    async loadConfigFile () {
      this.config = await fetch('config.json').then(res => res.json())
        .then(data => {
          return data
        })
    },
    saveConfigFile () {
      this.$robot.filesystemService.saveFile(
        AvailableSaveMimeType.TEXT,
        JSON.stringify(this.config, null, '\t'),
        // '/var/www/roboshow/configs/roboshow.json'
        '/var/www/roboshow/config.json'
      )
    },
    getLLMSetting () {
      this.getPrompt()
    },
    saveLLMSettings () {
      this.saveConfigFile()
      this.savePrompt()
    },
    async getPrompt () {
      await axios({
        method: 'get',
        url: `http://127.0.0.1:8001/llm_prompt?llm=${this.config.LLM.id}`
      }).then(response => {
        this.prompt = response.data
      })
    },
    async savePrompt () {
      await axios({
        method: 'post',
        url: `http://127.0.0.1:8001/llm_prompt?llm=${this.config.LLM.id}`,
        data: this.prompt
      }).then(response => {
        console.log(response.data)
      })
    }
  }
})
</script>

<template>
  <div class="settingContainer">
    <div class="title">
      <div class="settingsOption">
        <div class="name">
          {{ $t('settings.llm.title') }}
        </div>
        <div class="value">
          <select v-model="config.LLM.id" @change="getLLMSetting">
            <option v-for="(item, index) in llmList" :key="index" :value="index">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="settingsOption textarea">
        <div class="name">
          {{ $t('settings.llm.prompt') }}
        </div>
        <div class="value">
          <textarea v-model="prompt"></textarea>
        </div>
      </div>
    </div>
    <div class="footer">
      <button @click="saveLLMSettings">{{ $t('settings.llm.save') }}</button>
    </div>
  </div>
</template>

<style scoped>
select {
  height: 64px;
  width: 428px;
  padding: 4px 4px 4px 24px;
  box-shadow: 0 0 0 2px #22242B inset;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

select::before {
  display: none;
}

.settingsOption.textarea {
  height: 180px;
}

textarea {
  padding: 4px;
  margin-right: 4px;
}
</style>
