<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingsSelect',
  data () {
    return {
      cards: [{
        title: 'settings.settings',
        image: 'settings.svg',
        to: '/settings/main'
      }, {
        title: 'settings.promo.title',
        image: 'promo.svg',
        to: 'settings/promo'
      }, {
        title: 'settings.changePassword',
        image: 'block.svg',
        to: '/settings'
      }]
    }
  },
  methods: {
    systemReboot () {
      this.$robot.settingsService.systemReboot()
    },
    refresh () {
      location.reload()
    },
    softwareRestart () {
      this.$robot.settingsService.softwareRestart()
    },
    pause () {
      this.$robot.settingsService.pause()
    },
    test () {
      window.open('https://www.mozilla.org/', 'mozillaWindow', 'popup')
    }
  }
})
</script>

<template>
  <div class="cards">
    <router-link :to="card.to" v-for="card in cards" :key="card.to">
      <div class="card">
        <div class="top">
          <img v-if="card.image" :src="require(`@/assets/icons/${card.image}`)" alt=""
               style="width: 64px; height: 64px;"/>
        </div>
        <div class="bottom">{{ $t(card.title) }}</div>
      </div>
    </router-link>

    <div class="card" @click="refresh">
      <div class="top">
        <img :src="require('@/assets/icons/reboot-gui.svg')" alt="" style="width: 64px; height: 64px;"/>
      </div>
      <div class="bottom">{{ $t('settings.refreshWebGui') }}</div>
    </div>

    <div class="card" @click="softwareRestart">
      <div class="top">
        <img :src="require('@/assets/icons/reboot-case.svg')" alt="" style="width: 64px; height: 64px;"/>
      </div>
      <div class="bottom">{{ $t('settings.softwareRestart') }}</div>
    </div>

    <div class="card" @click="pause">
      <div class="top">
        <img :src="require('@/assets/icons/stop.svg')" alt="" style="width: 64px; height: 64px;"/>
      </div>
      <div class="bottom">{{ $t('settings.pause') }}</div>
    </div>

    <div class="card" @click="systemReboot">
      <div class="top">
        <img :src="require('@/assets/icons/reboot-system.svg')" alt="" style="width: 64px; height: 64px;"/>
      </div>
      <div class="bottom">{{ $t('settings.systemReboot') }}</div>
    </div>

    <a href="https://google.com">
      <div class="card">
        <div class="top">
          <img :src="require('@/assets/icons/reboot-system.svg')" alt="" style="width: 64px; height: 64px;"/>
        </div>
        <div class="bottom">TEST</div>
      </div>
    </a>

    <div class="card" @click="test">
      <div class="top">
        <img :src="require('@/assets/icons/reboot-system.svg')" alt="" style="width: 64px; height: 64px;"/>
      </div>
      <div class="bottom">TEST</div>
    </div>

  </div>

</template>

<style scoped>
.cards {
  padding-top: 60px;
  display: flex;
  gap: 18px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  width: 1100px;
  margin: 0 auto;
}

.card {
  width: 320px;
  height: 210px;
  border: 4px solid #F2F2F2;
  box-shadow: 0 2px 4px 0 #0E111D40;
  padding: 0 0 0 0;
  gap: 24px;
  border-radius: 12px 0 0 0;
  background-color: #ffffff;
}

.card .top {
  height: 80px;
  padding: 48px 0 0 0;
  width: 100%;
  text-align: center;
}

.card .bottom {
  padding: 0 0 0 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;

}
</style>
