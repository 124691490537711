<script>
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'ticketsList',
  components: { CaseAside },
  data: function () {
    return {
      tickets: {
        data: {
          tickets: [{}]
        }
      },
      showItem: false,
      item: {}
    }
  },
  async mounted () {
    this.tickets = await fetch(`${Env.get('backend_host')}/ticketsList`).then(res => res.json())
      .then(data => {
        return data
      })
    console.log(this.tickets.data.tickets)
  },
  methods: {
    showTickets: function (item) {
      this.showItem = true
      this.item = item
    },
    formatDate (date) {
      const timestamp = Date.parse(date)
      if (isNaN(timestamp) === false) {
        const d = new Date(timestamp)
        const options = {
          dateStyle: 'short'
        }
        return d.toLocaleString(undefined, options)
      } else {
        return ''
      }
    }
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside :show-home-button="false"/>
    </div>

    <div class="container" v-if="tickets.data.tickets.length > 0 && !showItem" style=" width: 1560px;">
      <h1>{{ $t('scenarios.tickets.status.title') }}</h1>
      <div class="ticket-header">
        <div class="date">Дата</div>
        <div class="number">Номер</div>
        <div class="type">Тип</div>
        <div class="status">Статус</div>
        <div class="description">Текст заявки</div>
      </div>
      <div class="ticket-list">
        <div v-for="item in tickets.data.tickets" v-bind:key="item.id" @click="showTickets(item)" class="ticket">
          <div class="date">{{ formatDate(item.created_at) }}</div>
          <div class="number">{{ item.number }}</div>
          <div class="type">{{ item.title }} ({{ item.types && item.types.length > 0 ? item.types[0].id : '' }})</div>
          <div class="status">{{ item.status }}</div>
          <div class="description">{{ item.description }}</div>
          <!--          <span>{{ item.updated_at }}</span>-->
          <!--          <span>{{ item.date }}</span>-->
          <!--                      {{ item }}-->
        </div>
      </div>
    </div>
    <div class="container" v-if="showItem">
      <div>
        <h2>{{ item.title }}</h2>
        <span>{{ item.number }}</span>&nbsp;
        <span>{{ item.created_at }}</span>&nbsp;
        <span>{{ item.updated_at }}</span>&nbsp;
        <span>{{ item.status }}</span>&nbsp;
        <h4>{{ item.date }}</h4>

        <!--      <div v-if="item.images.length > 0">-->
        <!--        <img v-for="image in item.images" :key="image.id" :src="image" alt="">-->
        <!--      </div>-->

        <!--      {{ item }}-->
      </div>
    </div>
  </div>
</template>

<style scoped>

.ticket-list {
  height: 760px;
  overflow: auto;
}

.ticket {
  width: 1500px;
  height: 109px;
  padding: 0 0 0 20px;
  gap: 10px;
  display: flex;
  border-radius: 24px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  align-items: center;
  box-shadow: 0 20px 60px 0 rgba(130, 0, 4, 0.12);
}

.ticket div {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.ticket-header {
  width: 1500px;
  display: flex;
  padding: 0 0 0 20px;
  gap: 10px;
  height: 40px;
}

.ticket-header div {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
}

.date {
  width: 150px;
}

.number {
  width: 150px;
}

.type {
  width: 450px;
}

.status {
  width: 150px;
}

.description {
  width: 450px;
}

.ticket .number, .ticket .description {
  color: #808080;

}
</style>
