import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","height":"1080px"} }
const _hoisted_2 = { style: {"width":"360px"} }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaseAside = _resolveComponent("CaseAside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CaseAside)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('scenarios.info.title')), 1),
      _createElementVNode("h2", null, _toDisplayString(_ctx.data.data.title), 1),
      _createElementVNode("div", {
        style: {"height":"720px","overflow":"auto"},
        innerHTML: _ctx.data.data.text
      }, null, 8, _hoisted_3)
    ])
  ]))
}