<script lang="ts">
import { defineComponent } from 'vue'
import { AvailableSaveMimeType } from '@pb/api/dist/src/services/filesystem'

export default defineComponent({
  name: 'SettingsLanguage',
  data () {
    return {
      config: {
        PROMO: {
          files: Array<string>(),
          phrases: Array<string>()
        },
        LANGUAGES: {}
      }
    }
  },
  mounted () {
    this.loadConfigFile()
  },
  methods: {
    async loadConfigFile () {
      this.config = await fetch('config.json').then(res => res.json())
        .then(data => {
          return data
        })
    },
    saveConfigFile () {
      this.$robot.filesystemService.saveFile(
        AvailableSaveMimeType.TEXT,
        JSON.stringify(this.config, null, '\t'),
        // '/var/www/roboshow/configs/roboshow.json'
        '/var/www/roboshow/config.json'
      )
    }
  }
})
</script>

<template>
  <div class="settingContainer">
    <div class="body">
      <div v-for="item in config.LANGUAGES" :key="item['action']">
        <div class="settingsOption">
          <div class="name">
            <img :src="require(`@/assets/icons/flag_${item['imgName']}.svg`)" alt="" style="width: 48px; height: 48px;" />
            {{ item['text'] }}
          </div>
          <div class="value checkbox">
            <input type="checkbox" v-model="item['is_enabled']" :id="`cb${item['action']}`"/>
            <label :for="`cb${item['action']}`"/>
          </div>
        </div>
        <div class="divider"/>
      </div>
    </div>
    <div class="footer">
      <button @click="saveConfigFile">{{ $t('languages.save') }}</button>
    </div>
  </div>
</template>

<style scoped>

</style>
