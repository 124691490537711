<script lang="ts">

import { Env } from '@pb/configuration-helper'
import { defineComponent } from 'vue'

interface ILanguage {
  imgName: string;
  text: string;
  direction: string;
  action: string;
  id: number;
}

export default defineComponent({
  name: 'CaseAside',
  data () {
    return {
      isShowLanguageSelector: false
    }
  },
  props: {
    buttons: {
      type: String,
      default: '/'
    },
    showHomeButton: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    showLoginButton: {
      type: Boolean,
      default: true
    },
    showLanguageButton: {
      type: Boolean,
      default: true
    }//,
    // backButtonAction: {
    //   default: function () {
    //     history.back()
    //   }
    // }
  },
  computed: {
    selectedLanguages (): object {
      return Env.get('LANGUAGES') as object
    }
  },
  methods: {
    async setLanguage (language: ILanguage) {
      this.$i18n.locale = language.imgName
      this.$robot.settingsService.setLanguage(language.action.replace('-', '_'))
      const el = document.querySelector('html')
      const dir = language.direction
      if (el !== null) {
        el.setAttribute('dir', dir ?? 'ltr')
      }
      this.toggleSelector()
    },

    toggleSelector () {
      this.isShowLanguageSelector = !this.isShowLanguageSelector
    },

    getCurrentLanguage () {
      return this.$i18n.locale
    }
  }
})
</script>

<template>
  <div class="logo" style="width: 360px; height: 400px;">

  </div>
  <div class="asideButtons">

    <div class="asideButton home" v-if="showHomeButton">
      <router-link to="/menu">
        <div class="ico"></div>
        <div class="text">{{ $t('buttons.home') }}</div>
      </router-link>
    </div>

    <div class="asideButton back" onclick="history.back()" v-if="showBackButton">
      <div class="ico"></div>
      <div class="text">{{ $t('buttons.back') }}</div>
    </div>
    <div class="asideButton login" v-if="showLoginButton">
      <div class="ico"></div>
      <div class="text">{{ $t('buttons.login') }}</div>
    </div>
    <div class="asideButton language" v-if="showLanguageButton" @click="toggleSelector">
      <div class="ico"></div>
      <div class="text">{{ $t('chooseLanguage.abbr') }}</div>
    </div>
  </div>

  <div class="languageSelectorOverlay" @click="console.log('Overlay clicked!')" v-if="isShowLanguageSelector">
    <div class="languageSelector">
      <h2>{{ $t('chooseLanguage.title') }}</h2>
      <div class="languageList">
        <div v-for="language in selectedLanguages" :key="language.id" class="language" @click="setLanguage(language)">
          <img :src="require(`@/assets/icons/flag_${language['imgName']}.svg`)" alt="Flag"/>
          <span>
            {{ language.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.logo {
  background-image: url('/src/assets/image/kortros.svg');
  background-repeat: no-repeat;
  background-position: center 120px;
  background-size: 122px 176px;
}

.asideButtons {
  display: flex;
  height: 680px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.asideButton {
  width: 122px;
  height: 122px;
  border-radius: 24px;
  border: 1px solid #0000001A;
}

.asideButton:last-child {
  margin-bottom: 120px;
}

.asideButton .ico {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  height: 90px;
}

.asideButton.home .ico {
  background-image: url('/src/assets/icons/home.svg');
}

.asideButton.back .ico {
  background-image: url('/src/assets/icons/back.svg');
}

.asideButton.language .ico {
  background-image: url('/src/assets/icons/language.svg');
}

.asideButton.login .ico {
  background-image: url('/src/assets/icons/profile.svg');
}

.asideButton .text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.languageSelectorOverlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.languageSelector {
  position: absolute;
  background-color: white;
  width: 1396px;
  height: 840px;
  top: 120px;
  left: 262px;
  border-radius: 40px;
  z-index: 100;
}

.languageSelector h2 {
  margin-top: 88px;
  margin-left: 100px;
}

.languageSelector .language {
  margin: 0 100px;
  width: 1196px;
  height: 123px;
  border-radius: 24px;
  border: 1px solid #00000026;
  align-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}

.languageSelector .language span {
  margin-left: 26px;
}

.languageSelector .language img {
  margin-left: 26px;
  width: 80px;
  height: 80px;
}
</style>
