<template>
  <div style="display: flex;">
    <div style="width: 360px; height: 1080px;">
      <CaseAside/>
    </div>
    <div style="height: 100%; width: 100%;">
      <div class="dialog" id="dialog">
        <!--    <div class="dialog-content">-->
        <!--      <div class="user">ТЕСТ</div>-->
        <!--    </div>-->
        <!--    <div class="dialog-content">-->

        <!--      <div class="robot">ТЕСТ2</div>-->
        <!--    </div>-->
        <!--    <div class="dialog-content">-->

        <!--      <div class="user">ТЕСТ3</div>-->
        <!--    </div>-->
        <!--    <div class="dialog-content">-->
        <!--      <div class="robot">ТЕСТ4</div>-->
        <!--    </div>-->
      </div>
      <div class="buttons-ujin" id="buttonsUjin">

      </div>
      <div class="input-answer-ujin">
        <input type="text" placeholder="Введите сообщение" v-model="manualTextInput"/>
        <button class="btn-main" value="Отправить" @click="sendCommandFromButton(manualTextInput)">Отправить</button>
      </div>
<!--      <p id="text" style="position: absolute;-->
<!--    bottom: 20px;-->
<!--    width: 1920px;-->
<!--    text-align: center;-->
<!--    font-size: 48px;-->
<!--    white-space: nowrap;"></p>-->
<!--      <div-->
<!--        style="position: absolute; top: 0; right: 0; height: 100vh; width: 500px; background: aqua; font-size: 30px;"-->
<!--        id="info"-->
<!--      >-->
<!--      </div>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Env } from '@pb/configuration-helper'
import axios from 'axios'
import CaseAside from '@/views/components/CaseAside.vue'

export default defineComponent({
  name: 'TalkComponent',
  components: {
    CaseAside
  },
  inheritAttrs: false,
  props: {
    // videoName: {
    //   type: String,
    //   required: true
    // },
    // showHomeButton: {
    //   type: Boolean,
    //   default: false
    // },
    startUserPhrase: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      manualTextInput: ''
    }
  },
  async mounted () {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    // this.$robot.settingsService.setVolumeMic(100)
    this.$robot.dialogService.abortRobotReplic()
    this.$robot.dialogService.muteOn()

    let levels = [Env.get('LLM.id')]
    levels = [99]
    // const levels = [] as any
    this.$robot.getMessageChannel().send({
      type: 'dialog.set.level',
      levels
    })

    const dialogEl = document.getElementById('dialog')

    if (dialogEl) {
      dialogEl.innerText = ''
      dialogEl.innerHTML = ''
    }

    const buttonsDiv = document.getElementById('buttonsUjin')
    if (buttonsDiv) {
      buttonsDiv.innerHTML = ''
      buttonsDiv.innerText = ''
    }
    if (this.startUserPhrase !== '') {
      console.log('ОТПРАВИЛИ КОМАНДУ ПОЛЬЗОВАТЕЛЯ')
      this.sendCommandFromButton(this.startUserPhrase)
    }

    // const el = document.getElementById('text')
    // if (el) {
    //   this.$robot.dialogService.onUserReplic((text) => {
    //     el.innerHTML = text
    //   })
    // }

    this.$robot.dialogService.onUserReplic((text, isFinish) => {
      if (isFinish) {
        this.processQuestion(text)
      }
    })

    const el = document.getElementById('info')

    if (el) {
      // el.innerHTML = 'DEBUG<br>'
      this.$robot.dialogService.onAction(actions => {
        el.innerHTML += `onAction<br>${actions}<br>`
      })

      this.$robot.dialogService.onRobotReplicFinish(() => {
        el.innerHTML += 'onRobotReplicFinish<br>'
      })

      this.$robot.dialogService.onUserReplic((text, isFinish) => {
        el.innerHTML += `onUserReplic<br>${text}<br>${isFinish}<br>`
      })

      this.$robot.dialogService.onRobotReplicStart(text => {
        el.innerHTML += `onRobotReplicStart<br>${text}<br>`
      })

      this.$robot.dialogService.onRobotReplicFinish(() => {
        el.innerHTML += 'onRobotReplicFinish<br>'
      })

      this.$robot.dialogService.onQuestion((text, answers) => {
        el.innerHTML += `onQuestion1<br>${text}<br>${answers}<br>`
      }, () => {
        el.innerHTML += 'onQuestion2<br>'
      })

      this.$robot.dialogService.onLoadAnswer(answer => {
        el.innerHTML += `onLoadAnswer<br>${answer}<br>`
      })

      this.$robot.dialogService.onLoadReplic(replic => {
        el.innerHTML += `onLoadReplic<br>${replic}<br>`
      })
    }
  },
  unmounted () {
    // this.$robot.settingsService.setVolumeMic(0)
    // this.$robot.dialogService.muteOn()
    this.$robot.getMessageChannel().send({
      type: 'dialog.set.level',
      levels: []
    })
    this.$robot.dialogService.abortRobotReplic()
  },
  computed: {},
  methods: {
    processQuestion (text: string) {
      const dialogEl = document.getElementById('dialog')

      if (dialogEl) {
        const scrollToEnd = () => {
          dialogEl.scrollTop = dialogEl.scrollHeight
        }
        new MutationObserver(scrollToEnd).observe(dialogEl, { childList: true })

        const dialogContent = document.createElement('div')
        dialogContent.classList.add('dialog-content')

        const dialogContentText = document.createElement('div')
        dialogContentText.innerHTML = text
        dialogContentText.classList.add('user')

        dialogContent.append(dialogContentText)
        dialogEl.append(dialogContent)

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const SELF = this
        axios.post(`${Env.get('backend_host')}/message/${text}`)
          .then(function (response) {
            const dialogContent = document.createElement('div')
            dialogContent.classList.add('dialog-content')

            const dialogContentText = document.createElement('div')
            dialogContentText.innerHTML = response.data.data.content
            dialogContentText.classList.add('robot')

            dialogContent.append(dialogContentText)
            dialogEl.append(dialogContent)
            SELF.$robot.dialogService.sayText(response.data.data.content, true)

            const buttonsDiv = document.getElementById('buttonsUjin')
            if (buttonsDiv) {
              console.log(response.data.data.buttons)
              buttonsDiv.innerHTML = ''

              for (const [i, button] of Object.entries(response.data.data.buttons)) {
                // console.log(`${i}: ${button}`)
                const buttonEL = document.createElement('button')
                buttonEL.classList.add('btn-main')
                buttonEL.textContent = button as string
                buttonEL.onclick = function () {
                  SELF.sendCommandFromButton(i)
                }
                buttonsDiv.appendChild(buttonEL)
              }

              // response.data.data.buttons.forEach((button: string, i: string) => {
              //   const buttonEL = document.createElement('button')
              //   buttonEL.textContent = `${button} - ${i}`
              //   buttonEL.onclick = function () {
              //     console.log(i)
              //   }
              //   buttonsDiv.appendChild(buttonEL)
              // })
            }

            console.log(response)
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
    sendCommandFromButton (text: string) {
      this.processQuestion(text)
      this.manualTextInput = ''
    }
    // sayPhrase (text: string) {
    //   this.$robot.dialogService.sayText(text, true)
    // }
  }
}
)
</script>

<style>

.dialog {
  width: 100%;
  height: 750px;
  overflow: auto;
}

.dialog .dialog-content {
  width: 100%;
  height: 160px;
}

.dialog div div {
  min-width: 200px;
  min-height: 100px;
  width: auto;
  border-radius: 24px;
  padding: 30px 40px;
  margin: 10px;
  max-width: 600px;
}

.dialog .user {
  background: var(--main-color);
  color: white;
  float: right;
}

.dialog .robot {
  background: white;
  float: left;
  box-shadow: 0 10px 30px 0 #0000001A;

}

.video {
  height: 100%;

  video {
    object-fit: cover;
  }
}

.buttons-ujin {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  height: 125px;
  padding-top: 20px;
}

.input-answer-ujin {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  height: 125px;
  padding-top: 20px;
}
</style>
