import { PromobotAugmented as Promobot } from '@/extended/promobot'
import { App } from 'vue'

export default {
  install (app: App, robot: Promobot): void {
    app.config.globalProperties.$robot = robot
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $robot: Promobot
  }
}
