<script>
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'AcquaintanceRobot',
  components: { CaseAside },
  data: function () {
    return {
      isShowAgreement: false,
      userName: '',
      userPassword: '',
      userAgree: '',
      userLogin: '',
      currentStep: 1,
      qr: {
        data: {
          qr: {
            link: ''
          }
        }
      }
    }
  },
  async mounted () {

  },
  methods: {
    toggleAgreement () {
      this.isShowAgreement = !this.isShowAgreement
    },
    changeStep (step) {
      this.currentStep = step
      if (step === 2) {
        this.getQR()
      }
    },
    async getQR () {
      this.qr = await fetch(`${Env.get('backend_host')}/qr/get`).then(res => res.json())
        .then(data => {
          this.loading = false
          return data
        })
      console.log(this.qr)
    }
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>

    <div class="container" id="step-1" v-if="currentStep===1">
      <h2>
        {{ $t('scenarios.acquaintance.step-1.greetings') }}
      </h2>
      <p>
        {{ $t('scenarios.acquaintance.step-1.info') }}
      </p>
      <h4>
        {{ $t('scenarios.acquaintance.step-1.what-name') }}
      </h4>
      <input type="text" :placeholder="$t('scenarios.acquaintance.step-1.placeholder')" style="display: block"
             v-model="userName"/>

      <div class="checkbox-container">
        <input type="checkbox" id="agree" name="agree" value="1" v-model="userAgree"/>
        <i18n-t keypath="scenarios.acquaintance.step-1.agreement" tag="label" for="agree">
          <template v-slot:link>
            <span @click="toggleAgreement">{{ $t('scenarios.acquaintance.step-1.agreement-link') }}</span>
          </template>
        </i18n-t>
      </div>

      <div class="buttons-container">
        <button class="btn-main" :disabled="!userAgree" @click="this.changeStep(2)">
          {{ $t('buttons.continue') }}
        </button>
        <button class="btn-secondary">
          {{ $t('buttons.skip') }}
        </button>
      </div>
    </div>

    <div class="container" id="step-2" v-if="currentStep===2">
      <h2>
        {{ $t('scenarios.acquaintance.step-2.authorize') }}
      </h2>
      <p>
        {{ $t('scenarios.acquaintance.step-2.info') }}
      </p>
      <h4>
        {{ $t('scenarios.acquaintance.step-2.info-2') }}
      </h4>
      <!--      <input type="text" :placeholder="$t('scenarios.acquaintance.step-2.login-placeholder')" style="display: block"-->
      <!--             autocomplete="off"-->
      <!--             v-model="userLogin"/><br/>-->
      <!--      <input type="password" :placeholder="$t('scenarios.acquaintance.step-2.password-placeholder')"-->
      <!--             style="display: block" autocomplete="off"-->
      <!--             v-model="userPassword"/>-->

      <img :src="this.qr.data.qr.link" v-if="this.qr.data.qr.link !== ''" alt="" style="height: 320px; width: 320px;"/>
      <div class="buttons-container">
        <button class="btn-main">{{ $t('buttons.continue') }}</button>
        <button class="btn-secondary">{{ $t('buttons.skip') }}</button>
      </div>
    </div>

    <div class="container" id="step-2" v-if="currentStep===3">
      <h2>
        Спасибо!
      </h2>
      <div class="buttons-container">
        <button class="btn-main">Продолжить</button>
        <!--        <button class="btn-secondary">Пропустить</button>-->
      </div>
    </div>
  </div>

  <div class="agreementModalOverlay" v-if="isShowAgreement">
    <div class="agreementModal">
      <div class="header" @click="toggleAgreement">
        ЗАКРЫТЬ
      </div>
      <div class="text">
        <h2>Условия обработки и хранения персональных данных</h2>
        <p>Не следует, однако забывать, что новая модель организационной деятельности влечет за собой процесс
          внедрения
          и
          модернизации новых предложений. С другой стороны реализация намеченных плановых заданий в значительной
          степени
          обуславливает создание систем массового участия. Задача организации, в особенности же рамки и место обучения
          кадров в значительной степени обуславливает создание новых предложений. Таким образом сложившаяся структура
          организации требуют определения и уточнения форм развития.</p>

        <p>Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют определения и
          уточнения
          форм развития. Товарищи! постоянное информационно-пропагандистское обеспечение нашей деятельности требуют от
          нас
          анализа соответствующий условий активизации. Задача организации, в особенности же новая модель
          организационной
          деятельности влечет за собой процесс внедрения и модернизации соответствующий условий активизации.
          Разнообразный
          и богатый опыт начало повседневной работы по формированию позиции требуют от нас анализа направлений
          прогрессивного развития.</p>

        <p>Не следует, однако забывать, что постоянный количественный рост и сфера нашей активности влечет за собой
          процесс внедрения и модернизации форм развития. Задача организации, в особенности же начало повседневной
          работы
          по формированию позиции влечет за собой процесс внедрения и модернизации новых предложений. Значимость этих
          проблем настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей деятельности
          требуют
          определения и уточнения форм развития. Разнообразный и богатый опыт новая модель организационной
          деятельности
          представляет собой интересный эксперимент проверки позиций, занимаемых участниками в отношении поставленных
          задач. Разнообразный и богатый опыт консультация с широким активом играет важную роль в формировании модели
          развития.</p>

        <p>Повседневная практика показывает, что консультация с широким активом позволяет оценить значение
          существенных
          финансовых и административных условий. С другой стороны сложившаяся структура организации требуют от нас
          анализа
          модели развития. Задача организации, в особенности же постоянный количественный рост и сфера нашей
          активности
          влечет за собой процесс внедрения и модернизации соответствующий условий активизации.</p>

        <p>Равным образом постоянное информационно-пропагандистское обеспечение нашей деятельности влечет за собой
          процесс
          внедрения и модернизации позиций, занимаемых участниками в отношении поставленных задач. Значимость этих
          проблем
          настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей деятельности в
          значительной
          степени обуславливает создание позиций, занимаемых участниками в отношении поставленных задач. Идейные
          соображения высшего порядка, а также укрепление и развитие структуры обеспечивает широкому кругу
          (специалистов)
          участие в формировании системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый
          опыт реализация намеченных плановых заданий влечет за собой процесс внедрения и модернизации системы
          обучения
          кадров, соответствует насущным потребностям. Повседневная практика показывает, что рамки и место обучения
          кадров
          требуют определения и уточнения позиций, занимаемых участниками в отношении поставленных задач. Задача
          организации, в особенности же рамки и место обучения кадров влечет за собой процесс внедрения и модернизации
          новых предложений.</p>

        <p>Таким образом постоянное информационно-пропагандистское обеспечение нашей деятельности требуют от нас
          анализа
          позиций, занимаемых участниками в отношении поставленных задач. Задача организации, в особенности же рамки и
          место обучения кадров позволяет оценить значение дальнейших направлений развития. Идейные соображения
          высшего
          порядка, а также начало повседневной работы по формированию позиции способствует подготовки и реализации
          системы
          обучения кадров, соответствует насущным потребностям. Значимость этих проблем настолько очевидна, что
          постоянный
          количественный рост и сфера нашей активности в значительной степени обуславливает создание новых
          предложений.
          Товарищи! начало повседневной работы по формированию позиции представляет собой интересный эксперимент
          проверки
          модели развития.</p>

      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  margin-left: 40px;
  margin-right: 140px;
}

h2 {
  margin-top: 108px;
  margin-bottom: 25px;
}

h4 {
  margin-bottom: 32px;
}

.checkbox-container label {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.checkbox-container label span {
  color: var(--main-color);
}

.buttons-container {
  padding-top: 135px;
}

.agreementModalOverlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.agreementModal {
  position: absolute;
  background-color: white;
  width: 1396px;
  height: 840px;
  top: 120px;
  left: 262px;
  border-radius: 40px;
  z-index: 100;
}

.agreementModal div.header {
  height: 80px;
  align-content: center;
  padding-right: 40px;
  text-align: right;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  opacity: 35%;
}

.agreementModal div.header:after {
  content: url("/src/assets/icons/cross.svg");
  width: 20px;
  height: 20px;
}

.agreementModal div.text {
  margin: 0 100px;
  height: 740px;
  overflow: auto;
}

.agreementModal h2 {
  margin-top: 48px;
}

.agreementModal p {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;

}

.agreementModal .language {
  width: 1196px;
  height: 123px;
  border-radius: 24px;
  border: 1px solid #00000026;
  align-content: center;
  display: flex;
  align-items: center;
  margin: 0 100px 20px;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}

.agreementModal .language span {
  margin-left: 26px;
}

.agreementModal .language img {
  margin-left: 26px;
  width: 80px;
  height: 80px;
}
</style>
