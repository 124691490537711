<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import YAML from 'yaml'

export default defineComponent({
  name: 'SettingsTTS',
  data () {
    return {
      ttsList: [],
      currentTTS: '',
      configFile: '',
      voices: {
        ru_RU: ['ru-RU-Wavenet-B', 'ru-RU-Wavenet-D'],
        en_US: ['en-US-Wavenet-B', 'en-US-Wavenet-D'],
        hi_HI: ['hi-IN-Wavenet-B', 'hi-IN-Wavenet-C'],
        ar_AE: ['ar-XA-Wavenet-B', 'ar-XA-Wavenet-C']
      }
    }
  },
  async mounted () {
    // this.ttsList = robotApi.getTTSList()
    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/tts_list'
    }).then(response => {
      this.ttsList = response.data
    })

    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/tts'
    }).then(response => {
      this.currentTTS = response.data
    })

    await this.getTTSSettings()
  },

  methods: {
    async getTTSSettings () {
      await axios({
        method: 'get',
        url: `http://127.0.0.1:8001/tts_settings?tts=${this.currentTTS}`
      }).then(response => {
        this.configFile = YAML.parse(response.data, { schema: 'yaml-1.1', version: '1.2', intAsBigInt: true })
      })
    },
    async saveTTSSettings () {
      await axios({
        method: 'post',
        url: 'http://127.0.0.1:8001/tts?tts=' + this.currentTTS
      }).then(response => {
        console.log(response.data)
      })

      await axios({
        method: 'post',
        url: 'http://127.0.0.1:8001/tts_settings?tts=' + this.currentTTS,
        data: YAML.stringify(this.configFile, { defaultStringType: 'QUOTE_DOUBLE', defaultKeyType: 'PLAIN' })
      })
    }
  }
})
</script>

<template>
  <div class="settingContainer">
    <div class="title">
      <div class="settingsOption">
        <div class="name">
          {{ $t('settings.tts.title') }}
        </div>
        <div class="value">
          <select v-model="currentTTS" @change="getTTSSettings()">
            <option v-for="(item) in ttsList" :key="item" :value="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="body">
      <div v-if="currentTTS === 'google' && configFile.rdi_tts_google" class="value">
        <div v-for="(item, index) in configFile.rdi_tts_google.ros__parameters.languages" :key="index">
          <div class="divider"></div>
          <div class="settingsOption">
            <div class="name disabled">
              {{ index }}
            </div>
          </div>
          <div class="settingsOption">
            <div class="name">
              {{ $t('settings.tts.voice') }}
            </div>
            <div class="value">
              <select v-if="voices[index]" v-model="item.voice">
                <option v-for="(item_voice, index_voice) in voices[index]" :key="index_voice" :value="item_voice">
                  {{ item_voice }}
                </option>
              </select>
              <input v-else type="text" v-model="item.voice"/>
            </div>
          </div>
          <div class="settingsOption">
            <div class="name">
              {{ $t('settings.tts.pitch') }}
            </div>
            <div class="value range">
              <input type="range" min="-20" max="20" step="0.1" v-model="item.pitch"/>
              <div>{{ item.pitch }}</div>
            </div>
          </div>
          <div class="settingsOption">
            <div class="name">
              {{ $t('settings.tts.rate') }}
            </div>
            <div class="value range">
              <input type="range" min="0.25" max="4" step="0.25" v-model.number="item.rate"/>
              <div>{{ item.rate }}</div>
            </div>
          </div>
          <div class="settingsOption">
            <div class="name">
              {{ $t('settings.tts.volume') }}
            </div>
            <div class="value range">
              <input type="range" min="-96" max="16" step="1" v-model.number="item.volume"/>
              <div>{{ item.volume }}</div>
            </div>
          </div>
          <!--          <div class="settingsOption">-->
          <!--            <div class="name">-->
          <!--              {{ $t('settings.tts.sample-rate') }}-->
          <!--            </div>-->
          <!--            <div class="value">-->
          <!--              <input type="number" style="text-align: right" v-model="item['sample-rate']"/>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div v-if="currentTTS === 'elevenlabs' && configFile.rdi_tts_elevenlabs" class="value">

        <div class="settingsOption">
          <div class="name">
            {{ $t('settings.tts.optimize_streaming_latency') }}
          </div>
          <div class="value range">
            <input type="range" min="0" max="4" step="1"
                   v-model="configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.optimize_streaming_latency"/>
            <div>{{ configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.optimize_streaming_latency }}</div>
          </div>
        </div>

        <div class="settingsOption">
          <div class="name">
            {{ $t('settings.tts.stability') }}
          </div>
          <div class="value range">
            <input type="range" min="0" max="1" step="0.05"
                   v-model="configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.stability"/>
            <div>{{ configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.stability }}</div>
          </div>
        </div>

        <div class="settingsOption">
          <div class="name">
            {{ $t('settings.tts.similarity_boost') }}
          </div>
          <div class="value range">
            <input type="range" min="0" max="1" step="0.05"
                   v-model="configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.similarity_boost"/>
            <div>{{ configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.similarity_boost }}</div>
          </div>
        </div>

        <div class="settingsOption">
          <div class="name">
            {{ $t('settings.tts.style') }}
          </div>
          <div class="value range">
            <input type="range" min="0" max="1" step="0.05"
                   v-model="configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.style"/>
            <div>{{ configFile.rdi_tts_elevenlabs.ros__parameters.languages.default.style }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="footer">
      <button @click="saveTTSSettings">
        {{ $t('settings.tts.save') }}
      </button>
    </div>
  </div>
</template>

<style scoped>

</style>
