<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import HomeButton from '@/views/components/homeButton.vue'

export default defineComponent({
  name: 'SettingsWiFi',
  components: { HomeButton },
  data () {
    return {
      wifiList: [],
      currentWiFi: '',
      selectedNetwork: '',
      showModal: false,
      password: ''
    }
  },
  async mounted () {
    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/wifi'
    }).then(response => {
      this.wifiList = response.data
    })

    await axios({
      method: 'get',
      url: 'http://127.0.0.1:8001/wifi_current'
    }).then(response => {
      this.currentWiFi = response.data
    })
  },
  methods: {
    selectNetwork (network: string) {
      this.selectedNetwork = network
      const elements = document.getElementsByClassName('settingsOption')
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('selected')
      }
      document.getElementById(`network_${network}`)?.classList.add('selected')
    },
    showConnect () {
      this.showModal = true
    },
    async connect () {
      await axios({
        method: 'get',
        url: `http://127.0.0.1:8001/wifi_connect?ssid=${this.selectedNetwork}&password=${this.password}`
      }).then(response => {
        this.currentWiFi = response.data
      })
    }
  }
})
</script>

<template>
  <div v-if="currentWiFi" class="settingContainer" style="height: 100px; min-height: 100px;">
    <div class="settingsOption">
      <div class="name"><img src="@/assets/icons/signal_24px-3.svg" alt=""/>{{ currentWiFi }}</div>
      <div class="value">{{ $t('settings.wifi.connected') }}</div>
    </div>
  </div>

  <div class="settingContainer" :class="currentWiFi?'settingContainerShort':''">
    <div class="title">
      <div class="settingsOption">
        <div class="name">
          {{ $t('settings.wifi.available_networks') }}
        </div>
      </div>
    </div>
    <div class="body">
      <div v-for="(item, index) in wifiList" :key="index">
        <div class="settingsOption" @click="selectNetwork(item)" :id="`network_${item}`">
          <div class="name">
            <img src="@/assets/icons/signal_24px-3.svg" alt=""/>
            {{ item }}
          </div>
          <div class="value connect" @click="showConnect()">{{ $t('settings.wifi.connect') }}</div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal" class="modal">
    <home-button action="/settings/wifi" @click="showModal=false"/>
    <h1>{{ $t('settings.wifi.enterPassword') }}</h1>
    <h3 class="ssid">{{ selectedNetwork }}</h3>
    <input type="password" v-model="password"/><br/>
    <button @click="connect">{{ $t('settings.wifi.connect') }}</button>
  </div>
</template>

<style scoped>
select {
  height: 64px;
  width: 428px;
  padding: 4px 4px 4px 24px;
  box-shadow: 0 0 0 2px #22242B inset;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

select::before {
  display: none;
}

.settingContainerShort {
  height: 620px;
}

.selected {
  background: #22242B1A;
}

.settingsOption .connect {
  display: none;
}

.settingsOption.selected .connect {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #006FBE;
}
</style>
