<script>
import { defineComponent } from 'vue'
import CaseAside from '@/views/components/CaseAside.vue'
import { Env } from '@pb/configuration-helper'

export default defineComponent({
  name: 'newsYK',
  components: { CaseAside },
  props: {
    newsType: {
      type: String,
      required: false,
      default: 'news'
    }
  },
  data: function () {
    return {
      news: {
        data: {
          items: [{}]
        }
      },
      showItem: false,
      item: {},
      loading: false,
      backend_host: Env.get('backend_host')
    }
  },
  async mounted () {
    console.log(this.test)
    this.loading = true
    const url = this.backend_host + (this.newsType === 'news' ? '/news' : '/announcements')
    this.news = await fetch(url).then(res => res.json())
      .then(data => {
        this.loading = false
        return data
      })
  },
  methods: {
    showNews: function (item) {
      this.showItem = true
      this.item = item
      const newsText = document.getElementById('newsText')
      if (newsText) {
        newsText.innerHTML = this.item.text
      }
    },
    formatDate (date) {
      const timestamp = Date.parse(date)
      if (isNaN(timestamp) === false) {
        const d = new Date(timestamp)
        const options = {
          dateStyle: 'short'
        }
        return d.toLocaleString(undefined, options)
      } else {
        return ''
      }
    }
  }
})
</script>

<template>
  <div style="display: flex; height: 1080px">
    <div style="width: 360px;">
      <CaseAside/>
    </div>

    <div v-if="!showItem && !loading">
      <h1>{{ newsType === 'news' ? $t('scenarios.news.title') : $t('scenarios.announcements.title') }}</h1>
      <div v-if="news.data.items && news.data.items.length > 0 && !showItem && !loading" class="news">
        <div v-for="item in news.data.items" v-bind:key="item.id" @click="showNews(item)" class="newsItem">
          <h4>{{ item.dt }}</h4>
          <h3>{{ item.title }}</h3>

          <!--      {{ item }}-->
        </div>
      </div>
    </div>
    <div class="container" v-if="showItem">
      <div v-if="item.images.length > 0">
        <img v-for="image in item.images" :key="image.id" :src="image" alt="">
      </div>

      <h1>{{ item.title }}</h1>
      <h4>{{ formatDate(item.date) }}</h4>
      <h4>{{ item.dt }}</h4>
      <div id="newsText" v-html="item.sub_title" style="white-space: pre;     text-wrap: balance;"></div>
      <div id="newsText" v-html="item.text"></div>
    </div>
  </div>
</template>

<style scoped>
.container {
  height: 1080px;
  overflow: auto;
}

.news {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  height: 796px;
}

.newsItem {
  color: white;
  width: 689px;
  height: 496px;
  border-radius: 40px;
  background-image: url("/src/assets/image/newsback.png");
}

.newsItem h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding-left: 57px;
  padding-top: 56px;
}

.newsItem h3 {
  padding-top: 190px;
  padding-left: 57px;
  padding-right: 56px;
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}
</style>
